<template>
  <validation-observer
    ref="form"
    v-slot="{ handleSubmit }"
    tag="form"
  >
    <b-form @submit.stop.prevent="handleSubmit(fetch)">
      <b-row>
        <!-- video url -->
        <b-col md="6">
          <b-form-group
            label="Video URL"
            label-for="video_url"
          >

            <validation-provider
              #default="{ errors }"
              vid="video_url"
              name="Video URL"
              rules="required|url"
            >
              <b-input-group>
                <b-form-input
                  id="video_url"
                  v-model="video_url"
                  placeholder="Add Video URL"
                />
                <b-input-group-append>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                  >
                    Fetch
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ video url -->

        <b-col
          v-if="video"
          md="12"
        >

          <h2>{{ video.name }}</h2>
          <a
            :href="video.video_url"
            target="_blank"
          >
            <img :src="video.image_url">
          </a>
        </b-col>

        <b-col
          v-if="video"
          md="6"
          class="my-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            type="button"
            @click="addVideo"
          >
            Add Video
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider, ValidationObserver, extend,
} from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const urlFixRule = value => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !!pattern.test(value)
}
extend('url', {
  validate: val => urlFixRule(val), message: 'This value must be a valid URL',
})
extend('required', required)
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      video_url: '',
      video: false,
    }
  },
  computed: {
    ...mapGetters({
      getUserRole: 'app/getUserRole',
      orgName: 'app/getOrgName',
    }),
  },
  methods: {

    async fetch() {
      const form = {
        video_url: this.video_url,
      }
      this.video = false
      await this.$http
        .post('/organizer/video/fetch', form)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Video Details Fetched',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.video = res.data.data
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })

          if (error.response.data) {
            const { errors } = error.response.data
            if (errors) {
              this.$refs.form.setErrors(errors)
            }
          }
        })
    },

    async addVideo() {
      const organisationId = localStorage.getItem('organizationSelected')
      if (organisationId > 0) {
        this.video.organization_id = organisationId
      }
      this.video.role = this.getUserRole
      await this.$http
        .post('/organizer/video', this.video)
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Video Added',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'videos' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Add Video Failed',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
